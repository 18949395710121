<template>
  <div>
    <section class="py-3 px-5 page-banner h-114">
      <b-row class="justify-content-between align-items-center">
        <b-col cols="12">
          <router-link to="/widgets" class="go-back-link"><BIconArrowLeftShort />Back to widgets</router-link>
        </b-col>
        <b-col cols="12" class="col-sm-6">
          <h3 class="page-header py-3">
            {{ isEditPage ? widgetDetails.widgetName : 'Add widget' }}
          </h3>
        </b-col>
        <b-col cols="12" class="col-sm-6 text-right mt-n4">
          <b-button
            type="button"
            v-activeBlur
            v-if="isEditPage"
            :disabled="restrictAddWidgetToMultipleCity || validateWidgetToSelectedTenant"
            class="transparent mr-3"
            @click="activateWidgetConfirmModal = true"
            >{{ widgetDetails.isActive ? `Deactivate` : `Activate` }}</b-button
          >
          <b-button
            type="button"
            v-activeBlur
            :disabled="restrictAddWidgetToMultipleCity || validateWidgetToSelectedTenant"
            v-if="isEditPage"
            class="transparent mr-3"
            @click="deleteWidgetConfirmModal = true"
            >Delete</b-button
          >
        </b-col>
      </b-row>
    </section>
    <section class="px-5 mt-4" v-if="restrictAddWidgetToMultipleCity">
      <b-form-row>
        <b-col>
          <b-alert show variant="danger">{{ DISPLAY_MESSAGES.MULTIPLE_CITIES_ADD_WIDGET_ERROR }}</b-alert>
        </b-col>
      </b-form-row>
    </section>
    <section class="px-5 mt-4" v-else-if="validateWidgetToSelectedTenant">
      <b-form-row>
        <b-col>
          <b-alert show variant="danger">{{ DISPLAY_MESSAGES.WIDGET_NOT_BELONG_TO_TENANT }}</b-alert>
        </b-col>
      </b-form-row>
    </section>
    <template v-else>
      <section class="px-5">
        <LoaderIcon v-if="isEditPage && widgetDetailsLoading" />
        <b-tabs
          v-else
          pills
          card
          vertical
          class="add-edit-widget-tabs"
          content-class="add-widget-content"
          nav-class="add-widget-tabs pl-0"
          active-nav-item-class="theme-font-bold"
        >
          <template v-for="(tab, tIndex) in tabs">
            <b-tab
              title-link-class="pl-0"
              :title="tab.label"
              :active="activeTab.value == tab.value"
              v-if="tab.display"
              :key="tIndex"
              @click="setActiveTab(null, tab)"
              lazy
            >
              <template #title>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="alert-icon">
                    {{ tab.label }}
                    <BIconExclamationCircleFill scale="0.8" v-if="tab.isVisited && tab.hasErrors" variant="danger" class="ml-1 visible" />
                    <BIconExclamationCircleFill scale="0.8" v-else class="ml-1 invisible" variant="danger" />
                  </div>
                  <div class="arrow-right-icon">
                    <ArrowRight v-if="activeTab.value == tab.value" />
                  </div>
                </div>
              </template>
              <h3 class="font-21 theme-font-medium pb-2">
                {{ activeTab.label }}
              </h3>
              <b-form id="add-edit-widget-from" name="add-edit-widget-from" autocomplete="off" @keyup.tab="pageScroll" novalidate>
                <keep-alive>
                  <component
                    :is="activeTab.value"
                    :widgetDetails="widgetDetails"
                    :formSubmitted="tab.isVisited"
                    :isEditPage="isEditPage"
                    @setDisplayTab="setDisplayTab"
                  ></component>
                </keep-alive>
              </b-form>
            </b-tab>
          </template>
        </b-tabs>
      </section>
      <div class="add-edit-widget-actions px-5 d-flex justify-content-between">
        <div class="back">
          <b-button
            v-if="activeTab.value != 'Configuration'"
            type="button"
            v-activeBlur
            variant="outline-secondary"
            @click="setActiveTab('back', null)"
            class="btn-h-58"
            ><ChevronLeftIcon class="mr-2" />Back</b-button
          >
        </div>
        <template v-if="!restrictAddWidgetToMaxAllowed && !restrictLocationToAddressSetUpNotDone">
          <div class="save" v-show="displaySaveBtn">
            <b-button type="button" v-activeBlur :disabled="saveLoadingIcon" @click="onSubmitWidget" variant="primary" class="btn-h-58"
              >Save<b-spinner v-if="saveLoadingIcon" label="Spinning" small class="ml-2"></b-spinner
            ></b-button>
          </div>
          <div class="next" v-show="!displaySaveBtn">
            <b-button type="button" v-activeBlur variant="primary" class="btn-h-58" @click="setActiveTab('next', null)"
              >Next <ChevronRightIcon class="ml-1"
            /></b-button>
          </div>
        </template>
      </div>
    </template>
    <ConfirmationModal
      :showModal="activateWidgetConfirmModal"
      :title="`CONFIRM`"
      :message="widgetDetails && widgetDetails.isActive ? `DEACTIVATE_WIDGET` : `ACTIVATE_WIDGET`"
      @onConfirm="activateOrDeactivateWidget"
      @closeConfirmModal="activateWidgetConfirmModal = false"
    />
    <ConfirmationModal
      :showModal="deleteWidgetConfirmModal"
      :title="`CONFIRM`"
      :message="`DELETE_WIDGET`"
      @onConfirm="deleteWidget"
      @closeConfirmModal="deleteWidgetConfirmModal = false"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { cloneDeep } from 'lodash'
import LoaderIcon from '../../../assets/svg/loader.svg'
import ArrowRight from '../../../assets/svg/arrow-right.svg'
import ChevronLeftIcon from '../../../assets/svg/chevron-left.svg'
import ChevronRightIcon from '../../../assets/svg/chevron-right.svg'
import { useValidateFields } from '@/composables/useValidateFields'
import { DISPLAY_MESSAGES, WidgetTypesSettings, ColorRegEx } from '../../../utilities/constants'
import { BIconArrowLeftShort, BIconChevronRight, BIconChevronLeft, BIconExclamationCircleFill } from 'bootstrap-vue'

export default {
  name: 'AddEditWidget',
  setup() {
    const { validateUrl, scrollToErrorMessage } = useValidateFields()

    return { validateUrl, scrollToErrorMessage }
  },
  data() {
    return {
      tabs: [
        {
          label: 'Configuration',
          value: 'Configuration',
          display: true,
          isVisited: false,
          hasErrors: false,
        },
        {
          label: 'Content',
          value: 'Content',
          display: true,
          isVisited: false,
          hasErrors: false,
        },
        {
          label: 'Link accounts',
          value: 'LinkAccounts',
          display: false,
          isVisited: false,
          hasErrors: false,
        },
        {
          label: 'Forms',
          value: 'Forms',
          display: false,
          isVisited: false,
          hasErrors: false,
        },
        {
          label: 'Notifications',
          value: 'Notifications',
          display: true,
          isVisited: false,
          hasErrors: false,
        },
        {
          label: 'Settings',
          value: 'Settings',
          display: false,
          isVisited: false,
          hasErrors: false,
        },
      ],
      activeTab: {
        label: 'Configuration',
        value: 'Configuration',
        display: true,
      },
      displaySaveBtn: false,
      ColorRegEx: ColorRegEx,
      widgetTypesSettings: WidgetTypesSettings,
      DISPLAY_MESSAGES: DISPLAY_MESSAGES,
      isEditPage: false,
      widgetFormCopy: null,
      activateWidgetConfirmModal: false,
      deleteWidgetConfirmModal: false,
      widgetDetails: {
        widgetCategoryID: Number(this.$route.params.widgetCategoryId),
        widgetName: null,
        widgetColor: null,
        widgetLogo: null,
        widgetLogoBlobURI: null,
        isIconUploaded: false,
        isFixedWidget: false,
        contentNotificationTemplate: {
          emailMessage: '',
          appMessage: '',
        },
        contentWidgets: {
          registeredContent: null,
          registeredContentExpanded: null,
          isNotified: false,
        },
        chartConfigurations: [],
        widgetTblConfigurations: [],
        calendarWidgetSettings: {
          dashboardItemCnt: null,
          expViewItemCnt: null,
          selectedCalendarTypes: [],
          categories: [],
          isNotified: false,
          layout: 'one-column-list',
          headingTxt: null,
          categoryHeadingTxt: null,
          locationHeadingTxt: 'Select Locations',
          isLocationEnabled: false,
          isCategoryDependent: true,
          isOnPublishedDate: true,
          daysPriorToEventDate: null,
        },
        adminNewsWidgetSettings: {
          dashboardItemCnt: null,
          expViewItemCnt: null,
          categories: [],
          isNotified: false,
        },
        locationWidgetSettings: {
          apiUrl: null,
          isFormat: true,
          selectedView: 'form-view',
          keyWidth: null,
          valueWidth: null,
          dashboardItemCnt: null,
          isNotified: false,
        },
        integrationWidgetSettings: {
          apiUrl: null,
          isFormat: true,
          selectedView: 'form-view',
          keyWidth: null,
          valueWidth: null,
          dashboardItemCnt: null,
          authenticationType: 'link-account',
          linkAccountDetails: null,
          unlinkedWidgetTxt: null,
          linkAccountTxt: null,
          unLinkAccountTxt: null,
          isNotified: false,
          isPropertyDependent: true,
        },
        formBuilderWidgetSettings: {
          categoryLst: [],
          forms: [],
          statusLst: [],
          actionLinkTxt: null,
          dashboardItemCnt: null,
          isNotified: false,
          isPropertyDependent: true,
          showSearchBar: false,
        },
        engagementWidgetSettings: {
          engagementPlatform: 'bang-the-table',
          isNotified: false,
        },
        myWasteWidgetSettings: {
          platform: null,
          isNotified: false,
        },
        recreationWidgetSettings: {
          recreationPlatform: 'perfect-mind',
          isNotified: false,
          actionLinkTxt: null,
        },
      },
      widgetDetailsLoading: false,
      saveLoadingIcon: false,
    }
  },
  components: {
    LoaderIcon,
    ArrowRight,
    ChevronLeftIcon,
    ChevronRightIcon,
    BIconArrowLeftShort,
    BIconChevronRight,
    BIconChevronLeft,
    BIconExclamationCircleFill,
    Configuration: () => import('./Configuration.vue'),
    Content: () => import('./Content.vue'),
    Notifications: () => import('./Notifications.vue'),
    LinkAccounts: () => import('./LinkAccounts.vue'),
    Forms: () => import('./Forms.vue'),
    Settings: () => import('./settings/Settings.vue'),
    ConfirmationModal: () => import('../../common/ConfirmationModal.vue'),
  },
  computed: {
    restrictLocationToAddressSetUpNotDone() {
      return this.selectedTenant.length && !this.selectedTenant[0].isAddressSetupDone && this.widgetDetails.widgetCategoryID === 4
    },
    restrictAddWidgetToMultipleCity() {
      return this.selectedTenant.length > 1 || this.selectedTenant.some((tenant) => tenant.tenantID === 0)
    },
    validateWidgetToSelectedTenant() {
      return (
        this.isEditPage &&
        this.selectedTenant.length === 1 &&
        this.widgetDetails.tenantID > 0 &&
        this.selectedTenant[0].tenantID !== this.widgetDetails.tenantID
      )
    },
    restrictAddWidgetToMaxAllowed() {
      return (
        !this.isEditPage &&
        this.applicableWidgets.some((widget) => widget.widgetCategoryId === this.widgetDetails.widgetCategoryID && widget.existingCnt >= widget.allowedCnt)
      )
    },
    ...mapState({
      selectedTenant: (state) => state.common.selectedTenant,
      applicableWidgets: (state) => state.widgets.applicableWidgets,
      bangTheTableIsValid: (state) => state.widgets.bangTheTableIsValid,
      perfectMindConfigIsValid: (state) => state.widgets.perfectMindConfigIsValid,
    }),
  },
  created() {
    if (this.$route.name === 'Edit-widget') {
      this.isEditPage = true
      this.getWidgetDetails()
    }
    this.getContentEmailTemplate()
  },
  mounted() {
    this.setDisplayTab()
  },
  methods: {
    pageScroll(event) {
      // tab = 9
      if (event.keyCode === 9) {
        const position = event.target.getBoundingClientRect()
        const padding = 133 // 85 + 48 = 133 action fixed footer height = 85 and input field height 48
        const viewHeight = window.innerHeight - padding
        const scrollPosition = window.top.scrollY
        if (position.top > viewHeight) {
          window.scrollTo(0, scrollPosition + padding)
        }
      }
    },
    showToaster(message, key, type) {
      this.$store.commit('common/setCustomToastData', {
        message: message,
        key: key,
        type: type,
      })
    },
    setDisplayTab() {
      this.tabs.forEach((tab) => {
        const selectedWidgetType = this.widgetTypesSettings.find((widget) => widget.widgetCategoryID === this.widgetDetails.widgetCategoryID)
        if (selectedWidgetType) {
          tab.display = selectedWidgetType.tabs.indexOf(tab.value) > -1
          if (tab.value === 'Notifications') {
            if (
              (this.widgetDetails.widgetCategoryID === 4 && !this.widgetDetails.locationWidgetSettings.isFormat) ||
              (this.widgetDetails.widgetCategoryID === 5 && !this.widgetDetails.integrationWidgetSettings.isFormat)
            ) {
              tab.display = false
            }
          }
        }
      })
      this.setPropertyDependent()
    },
    setActiveTab(type, selectedTab) {
      const displayTabs = this.tabs.filter((tab) => tab.display)
      const index = displayTabs.findIndex((tab) => tab.value === this.activeTab.value)
      displayTabs[index].isVisited = true
      if (type) {
        this.activeTab = type === 'back' ? displayTabs[index - 1] : displayTabs[index + 1]
      } else {
        this.activeTab = selectedTab
      }
      const activeIndex = displayTabs.findIndex((tab) => tab.value === this.activeTab.value)
      this.displaySaveBtn = activeIndex + 1 === displayTabs.length
      this.scrollToErrorMessage()
      this.validateWidgetForm()
    },
    getWidgetDetails() {
      this.widgetDetailsLoading = true
      this.$store
        .dispatch('widgets/getWidgetDetails', this.$route.params.widgetID)
        .then((response) => {
          this.widgetDetailsLoading = false
          this.widgetDetails = response.data
          this.setDisplayTab()
        })
        .catch(() => {
          this.widgetDetailsLoading = false
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'FAILED',
            type: 'danger',
          })
        })
    },
    getContentEmailTemplate() {
      if (this.widgetDetails.widgetCategoryID === 1) {
        let widgetId = null
        if (this.$route.name === 'Edit-widget') {
          widgetId = this.$route.params.widgetID
        }
        this.$store
          .dispatch('widgets/getContentEmailTemplate', widgetId)
          .then((response) => {
            if (response.data) {
              this.widgetDetails.contentNotificationTemplate.appMessage = response.data.appMessage
              this.widgetDetails.contentNotificationTemplate.emailMessage = response.data.emailMessage
            }
          })
          .catch(() => {})
      }
    },
    activateOrDeactivateWidget() {
      const postObj = {
        isActive: !this.widgetDetails.isActive,
        widgetID: this.widgetDetails.widgetID,
      }
      this.$store
        .dispatch('widgets/activateOrDeactivateWidget', postObj)
        .then((response) => {
          this.activateWidgetConfirmModal = false
          if (response.data && Object.hasOwn(response.data, 'errorMessage')) {
            this.showToaster(response.data.errorMessage, false, 'danger')
          } else if (response.data) {
            this.getWidgetDetails()
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: !this.widgetDetails.isActive ? 'ACTIVATE_WIDGET' : 'DEACTIVATE_WIDGET',
              type: 'success',
            })
          } else {
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: 'FAILED',
              type: 'danger',
            })
          }
        })
        .catch(() => {
          this.activateWidgetConfirmModal = false
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'FAILED',
            type: 'danger',
          })
        })
    },
    deleteWidget() {
      const postObj = {
        userID: 0,
        widgetID: this.widgetDetails.widgetID,
        comments: 'Deleting widget',
      }
      this.$store
        .dispatch('widgets/deleteWidget', postObj)
        .then((response) => {
          this.deleteWidgetConfirmModal = false
          if (response.data) {
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: 'DELETE_WIDGET',
              type: 'success',
            })
            this.$router.push('/widgets').catch(() => {})
          } else {
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: 'FAILED',
              type: 'danger',
            })
          }
        })
        .catch(() => {
          this.deleteWidgetConfirmModal = false
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'FAILED',
            type: 'danger',
          })
        })
    },
    validateCalendarCategories() {
      return this.widgetDetails.calendarWidgetSettings.categories.filter((category) => category.isSelected === true).length === 0
    },
    validateNewsCategories() {
      return this.widgetDetails.adminNewsWidgetSettings.categories.filter((category) => category.isSelected === true).length === 0
    },
    validateConfiguration() {
      const name = !!this.widgetDetails.widgetName
      const color = this.widgetDetails.widgetColor ? this.widgetDetails.widgetColor.match(ColorRegEx) !== null : false
      const logo = this.widgetDetails.isIconUploaded
        ? !!(this.widgetDetails.widgetLogo || this.widgetDetails.widgetLogoBlobURI)
        : !!this.widgetDetails.widgetLogoBlobURI
      const bangTheTable = this.widgetDetails.widgetCategoryID === 7 ? this.bangTheTableIsValid : true
      const perfectMind = this.widgetDetails.widgetCategoryID === 9 ? this.perfectMindConfigIsValid : true
      const myWastePlatform = this.widgetDetails.widgetCategoryID === 8 ? !!this.widgetDetails.myWasteWidgetSettings.platform : true
      const result = name && color && logo && !this.restrictAddWidgetToMaxAllowed && bangTheTable && perfectMind && myWastePlatform
      const index = this.tabs.findIndex((tab) => tab.value === 'Configuration')
      this.tabs[index].hasErrors = !result
      return result
    },
    validateContent() {
      const locationApiEndpoint = this.widgetDetails.widgetCategoryID === 4 ? this.validateUrl(this.widgetDetails.locationWidgetSettings.apiUrl) : true
      const integrationApiEndpoint = this.widgetDetails.widgetCategoryID === 5 ? this.validateUrl(this.widgetDetails.integrationWidgetSettings.apiUrl) : true
      const locationSelectedView =
        this.widgetDetails.widgetCategoryID === 4 && this.widgetDetails.locationWidgetSettings.isFormat
          ? !!this.widgetDetails.locationWidgetSettings.selectedView
          : true
      const integrationSelectedView =
        this.widgetDetails.widgetCategoryID === 5 && this.widgetDetails.integrationWidgetSettings.isFormat
          ? !!this.widgetDetails.integrationWidgetSettings.selectedView
          : true
      const locationKeyWidth =
        this.widgetDetails.widgetCategoryID === 4 && this.widgetDetails.locationWidgetSettings.selectedView === 'table-view'
          ? Number.isInteger(this.widgetDetails.locationWidgetSettings.keyWidth)
            ? !!(Number.isInteger(this.widgetDetails.locationWidgetSettings.keyWidth) && this.widgetDetails.locationWidgetSettings.keyWidth <= 100)
            : false
          : true
      const integrationKeyWidth =
        this.widgetDetails.widgetCategoryID === 5 && this.widgetDetails.integrationWidgetSettings.selectedView === 'table-view'
          ? Number.isInteger(this.widgetDetails.integrationWidgetSettings.keyWidth)
            ? !!(Number.isInteger(this.widgetDetails.integrationWidgetSettings.keyWidth) && this.widgetDetails.integrationWidgetSettings.keyWidth <= 100)
            : false
          : true
      const locationValueWidth =
        this.widgetDetails.widgetCategoryID === 4 && this.widgetDetails.locationWidgetSettings.selectedView === 'table-view'
          ? Number.isInteger(this.widgetDetails.locationWidgetSettings.valueWidth)
            ? !!(Number.isInteger(this.widgetDetails.locationWidgetSettings.valueWidth) && this.widgetDetails.locationWidgetSettings.valueWidth <= 100)
            : false
          : true
      const integrationValueWidth =
        this.widgetDetails.widgetCategoryID === 5 && this.widgetDetails.integrationWidgetSettings.selectedView === 'table-view'
          ? Number.isInteger(this.widgetDetails.integrationWidgetSettings.valueWidth)
            ? !!(Number.isInteger(this.widgetDetails.integrationWidgetSettings.valueWidth) && this.widgetDetails.integrationWidgetSettings.valueWidth <= 100)
            : false
          : true
      const dasContent = this.widgetDetails.widgetCategoryID === 1 ? !!this.widgetDetails.contentWidgets.registeredContent : true
      const dasExContent = this.widgetDetails.widgetCategoryID === 1 ? !!this.widgetDetails.contentWidgets.registeredContentExpanded : true
      const calendarType = this.widgetDetails.widgetCategoryID === 2 ? !!this.widgetDetails.calendarWidgetSettings.selectedCalendarTypes.length : true
      const calendarCategories = this.widgetDetails.widgetCategoryID === 2 ? !this.validateCalendarCategories() : true
      const calendarDashboardItems = this.widgetDetails.widgetCategoryID === 2 ? !!this.widgetDetails.calendarWidgetSettings.dashboardItemCnt : true
      const calendarDashboardExpandedViewItems = this.widgetDetails.widgetCategoryID === 2 ? !!this.widgetDetails.calendarWidgetSettings.expViewItemCnt : true
      const newsCategories = this.widgetDetails.widgetCategoryID === 3 ? !this.validateNewsCategories() : true
      const newsDashboardItems = this.widgetDetails.widgetCategoryID === 3 ? !!this.widgetDetails.adminNewsWidgetSettings.dashboardItemCnt : true
      const newsDashboardExpandedViewItems = this.widgetDetails.widgetCategoryID === 3 ? !!this.widgetDetails.adminNewsWidgetSettings.expViewItemCnt : true
      const locationDashboardItems =
        this.widgetDetails.widgetCategoryID === 4 && this.widgetDetails.locationWidgetSettings.isFormat
          ? !!this.widgetDetails.locationWidgetSettings.dashboardItemCnt
          : true
      const integrationDashboardItems =
        this.widgetDetails.widgetCategoryID === 5 && this.widgetDetails.integrationWidgetSettings.isFormat
          ? !!this.widgetDetails.integrationWidgetSettings.dashboardItemCnt
          : true
      const unlinkedWidgetTxt = this.widgetDetails.widgetCategoryID === 5 ? !!this.widgetDetails.integrationWidgetSettings.unlinkedWidgetTxt : true
      const linkAccountTxt = this.widgetDetails.widgetCategoryID === 5 ? !!this.widgetDetails.integrationWidgetSettings.linkAccountTxt : true
      const unLinkAccountTxt = this.widgetDetails.widgetCategoryID === 5 ? !!this.widgetDetails.integrationWidgetSettings.unLinkAccountTxt : true
      const formBuilderActionLinkTxt = this.widgetDetails.widgetCategoryID === 6 ? !!this.widgetDetails.formBuilderWidgetSettings.actionLinkTxt : true
      const formDashboardItemCnt = this.widgetDetails.widgetCategoryID === 6 ? !!this.widgetDetails.formBuilderWidgetSettings.dashboardItemCnt : true
      const recreationActionLinkTxt = this.widgetDetails.widgetCategoryID === 9 ? !!this.widgetDetails.recreationWidgetSettings.actionLinkTxt : true
      const result =
        locationApiEndpoint &&
        integrationApiEndpoint &&
        locationSelectedView &&
        integrationSelectedView &&
        locationKeyWidth &&
        integrationKeyWidth &&
        locationValueWidth &&
        integrationValueWidth &&
        dasContent &&
        dasExContent &&
        calendarType &&
        calendarCategories &&
        calendarDashboardItems &&
        calendarDashboardExpandedViewItems &&
        newsCategories &&
        newsDashboardItems &&
        newsDashboardExpandedViewItems &&
        locationDashboardItems &&
        integrationDashboardItems &&
        unlinkedWidgetTxt &&
        linkAccountTxt &&
        unLinkAccountTxt &&
        formBuilderActionLinkTxt &&
        formDashboardItemCnt &&
        recreationActionLinkTxt
      const index = this.tabs.findIndex((tab) => tab.value === 'Content')
      this.tabs[index].hasErrors = !result
      return result
    },
    validateLinkAccounts() {
      const authMethod = this.widgetDetails.widgetCategoryID === 5 ? !!this.widgetDetails.integrationWidgetSettings.authenticationType : true
      const linkAccountDetails = this.widgetDetails.widgetCategoryID === 5 ? !!this.widgetDetails.integrationWidgetSettings.linkAccountDetails : true
      const result = authMethod && linkAccountDetails
      const index = this.tabs.findIndex((tab) => tab.value === 'LinkAccounts')
      this.tabs[index].hasErrors = !result
      return result
    },
    validateForms() {
      const forms = this.widgetDetails.widgetCategoryID === 6 ? !!this.widgetDetails.formBuilderWidgetSettings.forms.length : true
      const status = this.widgetDetails.widgetCategoryID === 6 ? !!this.widgetDetails.formBuilderWidgetSettings.statusLst.length : true
      const result = forms && status
      const index = this.tabs.findIndex((tab) => tab.value === 'Forms')
      this.tabs[index].hasErrors = !result
      return result
    },
    validateNotifications() {
      const appMessage = this.widgetDetails.widgetCategoryID === 1 ? !!this.widgetDetails.contentNotificationTemplate.appMessage : true
      const emailMessage = this.widgetDetails.widgetCategoryID === 1 ? !!this.widgetDetails.contentNotificationTemplate.emailMessage : true
      return appMessage && emailMessage
    },
    validateWidgetForm() {
      const configuration = this.validateConfiguration()
      const content = this.validateContent()
      const linkAccounts = this.validateLinkAccounts()
      const forms = this.validateForms()
      const notifications = this.validateNotifications()
      return configuration && content && linkAccounts && forms && notifications
    },
    onSubmitWidget() {
      const displayTabs = this.tabs.filter((tab) => tab.display)
      displayTabs.forEach((tab) => (tab.isVisited = true))
      this.scrollToErrorMessage()
      if (this.validateWidgetForm()) {
        this.saveLoadingIcon = true
        const action = this.isEditPage ? 'widgets/updateWidgetDetails' : 'widgets/addWidgetDetails'
        this.widgetFormCopy = cloneDeep(this.widgetDetails)
        this.widgetTypesSettings.forEach((settings) => {
          if (settings.widgetCategoryID !== this.widgetDetails.widgetCategoryID && settings.name !== 'contentWidgets') {
            this.widgetDetails[settings.name] = null
          }
        })
        if (!this.isEditPage) {
          this.widgetDetails.tenantID = this.selectedTenant[0].tenantID
        }
        if (this.widgetDetails.widgetCategoryID !== 1) {
          this.widgetDetails.contentWidgets.isNotified = false
        }
        if (this.widgetDetails.widgetCategoryID === 4) {
          if (!this.widgetDetails.locationWidgetSettings.isFormat) {
            this.widgetDetails.chartConfigurations = []
            this.widgetDetails.widgetTblConfigurations = []
          }
        } else if (this.widgetDetails.widgetCategoryID === 5) {
          if (!this.widgetDetails.integrationWidgetSettings.isFormat) {
            this.widgetDetails.chartConfigurations = []
            this.widgetDetails.widgetTblConfigurations = []
          }
        } else {
          this.widgetDetails.chartConfigurations = []
          this.widgetDetails.widgetTblConfigurations = []
        }

        if (this.widgetDetails.isIconUploaded) {
          this.widgetDetails.widgetLogoBlobURI = this.widgetDetails.widgetLogo ? null : this.widgetDetails.widgetLogoBlobURI
        } else {
          this.widgetDetails.widgetLogo = null
        }

        this.$store
          .dispatch(action, this.widgetDetails)
          .then((response) => {
            this.saveLoadingIcon = false
            if (response.data && Object.hasOwn(response.data, 'errorMessage')) {
              this.showToaster(response.data.errorMessage, false, 'danger')
            } else if (response.data) {
              this.showToaster(false, this.isEditPage ? 'UPDATE_WIDGET' : 'ADD_WIDGET', 'success')
              this.$router.push('/widgets').catch(() => {})
            } else {
              this.showToaster(false, 'FAILED', 'danger')
            }
            this.assignProperties()
          })
          .catch(() => {
            this.assignProperties()
            this.saveLoadingIcon = false
            this.showToaster(false, 'FAILED', 'danger')
          })
      } else {
        this.showToaster(false, 'ADD_WIDGET_RESOLVE_ERRORS', 'danger')
      }
    },
    assignProperties() {
      this.widgetDetails = cloneDeep(this.widgetFormCopy)
    },
    setPropertyDependent() {
      if (this.selectedTenant.length && !this.isEditPage) {
        if (this.widgetDetails.widgetCategoryID === 4 && !this.selectedTenant[0].isAddressSetupDone) {
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'ADDRESS_SETUP_NOT_DONE_ERROR',
            type: 'danger',
          })
        }
        if (this.widgetDetails.widgetCategoryID === 5) {
          this.widgetDetails.integrationWidgetSettings.isPropertyDependent = !!this.selectedTenant[0].isAddressSetupDone
        }
        if (this.widgetDetails.widgetCategoryID === 6) {
          this.widgetDetails.formBuilderWidgetSettings.isPropertyDependent = !!this.selectedTenant[0].isAddressSetupDone
        }
      }
    },
  },
  watch: {
    selectedTenant(newValue, oldValue) {
      this.setPropertyDependent()
    },
    widgetDetails: {
      deep: true,
      handler() {
        this.validateWidgetForm()
      },
    },
    bangTheTableIsValid() {
      this.validateWidgetForm()
    },
    perfectMindConfigIsValid() {
      this.validateWidgetForm()
    },
    'widgetDetails.widgetCategoryID'() {
      this.getContentEmailTemplate()
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .add-widget-content {
  .tab-pane {
    padding: 2.5rem 1.75rem !important;
  }
}
::v-deep .add-widget-tabs {
  border-right: 1px solid #d6d6d6;
  background-color: #fff;
  padding-top: 1.75rem;
  padding-right: 2.5rem;
  a {
    font-size: 18px;
    color: #000000;
  }
  .arrow-right-icon {
    position: absolute;
    right: 25px;
  }
}
.add-edit-widget-actions {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 85px;
  background-color: #fff;
  align-items: center;
  z-index: 1001;
  box-shadow: 0 0 20px #00000012;
}
</style>
